import React from "react"
import "./ai.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import CTA from "../components/cta"
import VerticalCarousel from "../components/vertical-carousel/vertical-carousel"

const icons = {
  hero: require("../images/ai/ai-cover.svg"),
  algorithms: require("../images/ai/trained-carousel.svg"),
  reinforcement: require("../images/ai/reinforcment-carousel.svg"),
  networks: require("../images/ai/simulated-carousel.svg"),
  deep: require("../images/ai/deeplearning-carousel.svg"),
}

const AI = () => {
  const carouselDataAi = [
    {
      title: "Trained algorithms",
      desc:
        "Understanding current and potential employee performance requires meticulous analysis. Scaling this across a business with thousands of employees requires carefully trained algorithms. The SHAPE team uses advanced mathematical models and cloud tools with continuous monitoring to learn, refine, learn and refine again.",
      contentImg: icons.algorithms,
      color: "dark-blue",
    },
    {
      title: "Reinforcement learning",
      desc:
        "Think about who works for who in large, complex organisations. To create an accurate organisational structure, we utilise the power of reinforcement learning, especially useful in helping fill the gaps when everyone cannot complete the survey. While we always depict reality, we’re busy learning the relational patterns where gaps occur to strengthen our already complex models.",
      contentImg: icons.reinforcement,
      color: "dark-blue",
    },
    {
      title: "Simulated networks",
      desc:
        "To a system, each employee is simply a node. Some nodes cluster, others are isolated. Scaling to multi-thousands of nodes benefits from simulated relationships to maintain rationality as a coherently connected end-to-end structure. Using a combination of clever mathematical solutions, together with neural mapping, we’re busy simulating the shape of different networks to find the many patterns that exist and perform the best.",
      contentImg: icons.networks,
      color: "dark-blue",
    },
    {
      title: "Deep learning",
      desc:
        "Accuracy is an obsession we continually strive for. Imagine knowing the future. Prediction is a super power, especially when it’s accurate. Finding patterns in data that transcend what statistical techniques might miss is an artful science. Additionally, applying the knowledge of people science, we identify powerful insights and recommendations that allow us to forecast more and more accurately.",
      contentImg: icons.deep,
      color: "dark-blue",
    },
  ]

  return (
    <Layout>
      <SEO title="SHAPE AI" />
      <div styleName="hero">
        <Container styleName="customContainer">
          <Row>
            <Col xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 6 }}>
              <div styleName="flexCenter">
                <h1>SHAPE AI</h1>
                <p styleName="subHeading">
                  <strong>Two big letters with a powerful meaning</strong>
                </p>
                <p>
                  Technology, data and mathematics form the beating heart of
                  SHAPE. We tap their power to deep learn, find insights, think
                  ahead and accurately guide.
                </p>
              </div>
            </Col>
            <Col xs={{ order: 12, span: 12 }} sm={{ order: 12, span: 6 }}>
              <div styleName="imgMarginSmall">
                <img src={icons.hero} alt="SHAPE AI" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div styleName="content">
        <VerticalCarousel data={carouselDataAi} />
      </div>
      <div styleName="contentEnd">
        <Container>
          <p>
            SHAPE is continually looking at cutting-edge machine learning, AI
            frameworks and tools. We keep exploring and optimising our models to
            deliver more efficiencies, predict better, increase accuracy and
            deliver higher value to you with meaningful insights.
          </p>
        </Container>
      </div>
      <CTA isFreeReport={true} />
    </Layout>
  )
}

export default AI
